import ActionAbstract from '../ActionAbstract';

class ListAvailableDSP extends ActionAbstract {

    static get ID() {
        return 'actions:faust:dsp:list';
    }

    run(state) {
        const field = this.getParam('field');

        if (!field.options || !field.options.length) {
            if (!field.options) field.options = [];
            this.deps.mainStore.faust.listDspAsOptions().then((options) => field.options = options);
        }

        return false;
    }
}

export default ListAvailableDSP;