<!-- Inspector vue -->
<template lang="pug">
div.inspector-panel(v-bind:class="target.value.type" )
	//div.geomodal-panel(v-if="state.main.geomodal && state.main.geomodal.open")
	div.inspector-content
		div.main-title
			div.type {{ blockTypeName }}
			div.help
				span.icon ?
				span.text {{ blockDescription }}
			input.invisible(:value="name", v-on:keyup="updateName")
			//div.url(v-if="['memory', 'session-set'].indexOf(type) === -1")
			div.url
				span URL :
				input.invisible(:value="slug", v-on:change="updateSlug")
				div.clear

		//div.cover(v-bind:class="{'open':pickerOpen}", v-on:click.stop="clickCoverHandler")

		div.content(v-if="inspectorField && target")

			// Bloc widget visibility manager
			widget-visibility-manager(:project="project", :target="target", v-if="hasWidgetManager()")

			div.children(v-for="bloc of inspectorField.children")
				topic(v-if="bloc.type=='topic'", :project="project", :target="target", :bloc="bloc", :state="state")
				widget(v-else, :project="project", :target="target", :bloc="bloc", :schematicLevel="inspector.schematic ? inspector.schematic.level : ''", :state="state", :inspectorField="inspectorField")

		div.content
			// Bloc targets for experiences inspector
			targets(:target="target", :available-targets="confTarget" v-if="target.value.type == 'experience'")

			// Bloc screens selection for experiences inspector
			screens(:target="target", v-if="target.value.type == 'experience'")

			// Bloc autoStart selection for process inspector
			auto-start-selector(:target="target", v-if="target.value.type == 'process'")

			// Bloc memory for process & screen inspector
			memory(:project="project", :appConfig="appConfig", :target="target", :inspector-conf="confMemory", v-if="hasMemory(target.value.type)")

			// Bloc session for project inspector
			sessions(:project="project", :target="target", v-if="target.value.type == 'project'")

			// Trigger bloc for trigger inspector
			trigger(:target="target", :project="project" v-if="target.value.type == 'trigger'")

		// Associated objects to configure
		div.content(v-for="objInspector of objectsToConfigure")
			div.children(v-for="bloc of objInspector.inspector.children")
				topic(v-if="bloc.type=='topic'", :project="project",  :target="objInspector.target", :bloc="bloc", :values="objInspector.values")

		div.accordion.save-zone
			div.form-content.open
				div.save-bt(v-on:click.stop="save()")
					span.text Save {{saveButtonLabel}}

</template>

<script>
	import * as helper from '../../helper';

	// STORE
	// import { stores } from '../../stores/MainStore.js';
	import actionManager from '../../actions';

	// OTHER SPECIFIC TOPICS
	import Memory from './specific/Memory.vue';
	import Trigger from './specific/Trigger.vue';
	import Targets from './specific/Targets.vue';
	import Screens from './specific/Screens.vue';
	import AutoStartSelector from './specific/AutoStartSelector.vue';
	import WidgetVisibilityManager from './specific/WidgetVisibilityManager.vue';
	import Sessions from './specific/Sessions.vue';

	// INSPECTOR WIDGET
	import Topic from "./blocs/Topic.vue";
	import Widget from "./widget/WidgetManager.vue";


	export default {

		// appConfig	the complete list of app's modules definition
		// project		the current projecr properties
		// inspector	the current inspector properties
		// workspace	the current working space (i.e. project or template)
		// state		the current app state
		props: ['appConfig', 'project', 'inspector', 'workspace', 'state'],

		components: {
			Memory,
			Trigger,
			Targets,
			Topic,
			widget: Widget,
			Screens,
			AutoStartSelector,
			Sessions,
			WidgetVisibilityManager
		},

		data() {
			return {
				values: [],
			}

		},

		computed: {

			name: function() {
				return this.inspector.currentName || this.target.value.name
			},

			slug: function() {
				return this.target.value.slug;
			},

			objectsToConfigure: function() {

				let objectsAssociated = this.target.custom && this.target.custom.objects;

				let result = [];

				if (objectsAssociated) {

					objectsAssociated.forEach((obj) => {

						let format = helper.config.mapConfigConvertion( obj.value.format , this.$xpConfig.mapLibrary );
						let objInspector = helper.config.getConfigByType(this.appConfig, obj.value.type, format );

						if (objInspector && !objInspector.value.isDisplayed) {

							// this object is not displayed on arborescence, so we need to configure it inside this associated block inspector
							let subInspector = {
								target: obj,
								values: [],
								inspector: objInspector.value.inspector
							};
							this.parseField(subInspector.inspector, subInspector.values, subInspector.target);
							result.push(subInspector);
						}

					});

				}

				return result;
			},

			confTarget: function() {
				return helper.config.getConfigByType(this.appConfig, 'experience');
			},

			confMemory: function() {

				let memories = [];

				this.appConfig.forEach((mem) => {
					if (mem.value && mem.value.type == "memory")
						memories.push(mem);
				});

				return memories;
			},

			target: function() {
				let bloc = helper.block.getEntityByIDInWholeProject(this.project, this.inspector.targetId);
				if (!bloc) {
					// @todo: bloc should never be null
					console.error(`Invalid inspector target. The bloc is not found for the target ID '${this.inspector.targetId}'`);
					return {
						value: {
							name: 'unknown',
							format: 'unknown',
							type: 'unknown',
							slug: 'unknown'
						}
					};
				}
				return bloc;
			},

			inspectorPattern: function(){
				let format = helper.config.mapConfigConvertion( this.target.value.format , this.$xpConfig.mapLibrary );
				return helper.config.getConfigByType(this.appConfig, this.target.value.type, format );
			},

			blockTypeName: function(){
				if( this.inspectorPattern && this.inspectorPattern.value && this.inspectorPattern.value.name )
					return this.inspectorPattern.value.name;
				return this.target.value.type;
			},

			blockDescription: function(){
				if( this.inspectorPattern && this.inspectorPattern.value && this.inspectorPattern.value.resume )
					return this.inspectorPattern.value.resume;
				return 'This documentation is not available yet.'
			},

			inspectorField: function() {

				if (this.target && this.target.value._deleted) {
					return;
				}

				if( !this.inspectorPattern || !this.inspectorPattern.value ) return;

				let inspectorConf = this.inspectorPattern.value.inspector;


				if (inspectorConf) {
					this.parseField(inspectorConf, this.target);
				}


				return inspectorConf;

			},

			saveButtonLabel: function() {
				const {value} = this.target;
				if (value.format && value.format.startsWith('ui-')) return 'UI element';
				if (value.type === 'memory') return value.level;
				if (value.level === 'process') return 'module';
				return value.type || '';
			}

		},

		created: function() {
		},

		methods: {

			updateName: function(e) {
				actionManager.trigger('inspector:updateName', {
					targetId: this.inspector.targetId,
					inspector: this.inspector,
					newName: e.target.value
				});

			},

			updateSlug: function(e) {
				actionManager.trigger('inspector:updateSlug', {
					targetId: this.inspector.targetId,
					inspector: this.inspector,
					newSlug: e.target.value
				});

			},

			hasMemory: function(type) {
				let allowed = ['project', 'experience', 'screen', 'widget', 'process'];

				if (allowed.indexOf(type) == -1)
					return false;
				return true;
			},

			hasWidgetManager: function() {
				if (this.target.value.type === "object" && this.target.value.format === "ui-screen")
					return true;
				return false;
			},

			parseField: function(inspector, target) {


				if (!inspector)
					return;

				for (let child of inspector.children) {

					// S'il s'agit d'un champ

					if (child.type == "field" || child.type == "param")
						child.includedField = null;

					// Cas particulier:
					if (child.type == "field" && child.widget == "repeated-form") {
						helper.block.constructRepeatedForm(child);
					}
					if( child.type == "field" && child.widget == "dynamic-fields" ){
						helper.block.constructDynamicFieldsGroup(child);
					}

					// may be removed

					// S'il s'agit d'un champ color, on doit mettre la valeur dans un objet et la reliée au color picker et autres potentiels valeur

					if (child.widget == "color") {

						// this.pickers.tab[pickerID] = false;

						// values[child.name].pickerID = pickerID

						// on ajoute les champs reliés au color picker pour forcer la mise à jour de l'hexadecimal quand on change ces inputs

						if (child.colorFields) {

							target.custom.setting.value.inspector.colorFields.push({

								parent: child.name,

								children: child.colorFields

							});

						}



					}

					// end of useless code

					if (child.children && child.children.length > 0) {

						this.parseField(child, target);

					}

				}



			},

			save: function() {

				if (this.project.currentUserType !== "viewer"){
					actionManager.trigger(this.workspace+':save', { target: this.inspector.targetId });
				} else {
					actionManager.trigger('main:modal:openModal', {
						title: 'Viewer',
						text: 'You are currently a viewer of this project, which means you cannot save changes. Please contact the project owner if you would like to be added to the editors.',
						icon: 'icon_reader',
						cancelButton: '',
					});
				}
			},


			// clickCoverHandler: function() {
			// },

		}

	}
</script>

<style lang="stylus">

.geomodal-panel
	width: 436px
	height: 100%

.main-title
	height 50px
	line-height: 12px
	background-color: var(--mainTitleColor)
	padding: 10px 10px 0px 10px
	position: relative

	.type
		display:block;
		font-family: 'DinBold'
		font-size: 16px;
		line-height: 15px;
		color: white
		text-transform: capitalize;

	input
		display:inline-block;
		font-family: 'DinRegular'
		font-size: 12px
		line-height: 12px
		color: white
		padding: 0px 10px 0px 0px
		text-transform: unset
		user-select: text
		-webkit-user-select: text

	.url
		color: white
		font-size: 12px
		font-family: 'DinRegular', 'ComfortaaBold'

		span
			float: left
			margin-right: 0
			font-family: 'DinRegular', 'ComfortaaBold'
			line-height: 12px

		input
			width: auto
			float: left
			text-decoration: underline
			font-size: 12px
			padding-left: 4px
			line-height: 13px

.invisible-setting
	display none

.accordion
	margin-bottom: 1px

	.accordion-title
		height 40px
		line-height: 40px
		background-color: var(--bgColor)
		cursor:pointer
		position: relative
		span.text
			display:inline-block;
			font-family: 'DinBold', 'ComfortaaLight'
			font-size: 16px
			line-height: 40px
			padding-left: 10px
			padding-right: 10px
			color: var(--inspectorText)
			text-transform: capitalize
		span.arrow
			display:inline-block
			width: 14px
			height: 7px


			background-size: 12px auto
			background-repeat: no-repeat
			position: absolute
			right: 8px
			top: 50%
			transform: translateY(-40%)

			&.close
				transform: translateY(-60%) scaleY(-1)

	.form-content.open
		.save-bt
			.text
				font-size: 16px
				margin-top: -1px
				text-transform: capitalize

	.form-content
		padding: 10px 10px 0px 10px
		display: none

		&.open
			display: block

		&.not-first
			padding-top: 0px

		.form-accordion
			border-radius: 8px
			margin-bottom: 10px
			padding-bottom: 0.1px
			position: relative
			background: var(--inspectorLevel1)

			.title
				line-height: 40px
				vertical-align: middle
				background-color: var(--inspectorLevel1)
				padding: 0px 0px 0px 10px
				position: relative
				border-radius: 8px
				font-size: 14px
				transition: linear all 0.1s !important
				&:hover
					background-color: var(--inspectorTargetOverColor)


				span.text
					display:inline-block;
					font-family: 'DinBold'
					font-size: 14px
					line-height: 40px
					height: 40px
					position: relative
					color: var(--inputTextColor)
					text-transform: capitalize

				span.arrow
					display:inline-block
					width: 10px
					height: 5px
					background-image:url(../../assets/images/bt-arrow.png)
					background-size: 10px 5px
					background-repeat: no-repeat
					position: absolute
					right: 8px
					top: 50%
					transform: translateY(-50%)
					visibility: hidden

					&.close
						transform: translateY(-60%) scaleY(-1)

			.form-accordion-content
				display: none

				&.open
					display: block

			&.isAccordion
				.title
					cursor:pointer
					border-radius: 8px 8px 0px 0px
					font-family: 'DinBold'
					background: var(--subtitleColor)
					padding-left: 15px

				.form-accordion-content.open
					.textinput-ctn.trigger-select
						select
							background: var(--inputValue)
							height: 40px
							padding-left: 10px
							border-radius: 4px
							width: calc(100% - 5px)
							border: 0
							padding-top: 0
							margin-bottom: 5px!important
							font-family: 'DinBold'
							margin-top: 5px
							z-index:1
							outline: none!important
						.select-arrow ///////////////////
							z-index: 3
							background-image:url(../../assets/images/arrowDown.png)
							background-position: center
							background-size: 10px
							height: 40px
							width: 16px
							margin-top: -5px
							margin-right: 5px
							transform: rotate(0deg)!important
							outline: none!important

			&.group
				.title
					padding-left: 15px
					background: var(--subtitleColor)
					span.arrow
						right: auto
						left: 8px

			.form-accordion-content.open.widget-visibility.widgetSelection
				margin-bottom: 0px
				span.textinput-ctn.trigger-select
					margin-bottom: 5px
					height: 40px
					font-family: 'DinBold'
					span.bt-order
						width: 30px
						height: 30px
						border-radius: 4px
						display: inline-block
						float: right
						margin-top: 5px
						margin-right: 5px
						text-align: center
						cursor: pointer
						background: #f8c5a6
					span.bt-order.bt-bottom
						margin-left: 3px
						background-image:url(../../assets/images/bottom-arrow.png)
						background-size: 20px
						background-repeat: no-repeat
						background-position: center
					span.bt-order.bt-top
						background-image:url(../../assets/images/top-arrow.png)
						background-size: 20px
						background-repeat: no-repeat
						background-position: center

		.form-accordion.selectSet.isAccordion
			padding-bottom: 5px
			margin-bottom: 0.1px
			.big-bt.field-group
				.textinput-ctn.fullsize
					height: 40px
					margin: 5px
					select
						background: var(--inputValue)
						border-radius: 4px
						height: 40px
						margin: 0
						width: calc(100% - 2px)
					.select-arrow
						margin-top: 5px

		.form-accordion-content.open.homeScreen .textinput-ctn.trigger-select
			border-radius: 0 0 4px 4px

	.memory-bt
		background: var(--bgColor)
		padding: 0
		margin-top: 5px
		margin-left: 5px
		margin-right: 5px
		margin-bottom: 5px
		border-radius: 4px
		height: 40px
		position: relative
		transition: linear all .1s !important

	.memory-bt:hover
		background: var(--inputValue)

	.big-bt, .input-ctn

		&.field-group
			/*padding: 2px 2px 2px 8px*/

		&.has-header
			padding: 0px

		.header-ctn
			padding: 2px 0px
			position: relative
			border-bottom: 1px solid white

			.over-bts
				visible: hidden
				opacity: 0

			&:hover
				.over-bts
					visible: visible
					opacity: 1

			.header
				width: 80%
				padding: 3px 5px
				margin-bottom: 2px

		span.text
			font-family: 'DinBold'
			font-size: 14px
			position: relative
			color: var(--inputTextColor)
			margin-left: 10px
			font-size: 14px
			line-height: 40px
			white-space: nowrap
			overflow: hidden
			text-overflow: ellipsis
			text-transform: capitalize

		span.bt-plus
			font-family: 'DinBold'
			font-size: 16px
			color: white
			height: 20px
			width: 20px
			text-align: center
			display: inline-block
			position: absolute
			background: #f39a74
			right: 8px
			margin-top: 10px
			border-radius: 30px
			line-height: 21px

		input
			display:inline-block;
			font-family: 'DinRegular'
			font-size: 12px
			line-height: 26px
			vertical-align: middle
			color: #58585B
			padding: 0

			&.visible
				padding: 3px 5px
				background-color: transparent
				border: 1px solid #58585B
				border-radius: 3px

	.big-bt.bt-repeat-form
		margin: 0px !important
		margin-top: 5px !important
		margin-left: 5px !important
		margin-bottom: 5px !important
		width: calc(100% - 10px)
		&:hover
			background: var(--inputValue)
		.bt-plus
			right: 23px !important


	.key-value-ctn
		padding: 4px
		.key
			display: inline-block
			width: 28%
			margin-right: 1%
			font-family: 'DinRegular'
			line-height: 15px;
			color: #58585B
			font-size: 13px;
		.value
			float: right
			width: 65%

.bt-trash
	width: 20px
    height: 20px
    border-radius: 30px
    background-color: rgba(255,255,255,1)
	background-image: url(../../assets/images/deleteIcon.png)
	background-repeat: no-repeat
	background-size: auto 50%
	background-position: center center
	position: absolute
    top: 10px
    right: 10px
    cursor: pointer

.bt-unlink
	width: 10%
	height: 100%
	background-image: url(../../assets/images/bt-unlink.png)
	background-repeat: no-repeat
	background-size: auto 50%
	background-position: center center
	position: absolute
	top: 0
	right: 0
	cursor: pointer


.bt-link
	width: 10%
	height: 100%
	background-image: url(../../assets/images/bt-link.png)
	background-repeat: no-repeat
	background-size: auto 75%
	background-position: center center
	position: absolute
	top: 0
	right: 0
	cursor: pointer

.clear
	clear: both
	display: block


// .cover
// 	position: absolute
// 	z-index: 500
// 	top: 0
// 	right: 0
// 	left: 0
// 	bottom: 0
// 	background-color: #000
// 	display: none
// 	opacity: 0

// 	&.open
// 		display: block


.isDeprecated
	background-image: url(../../../assets/textureDeprecated.png) !important
	background-size: contain !important

	.description-bull
		background: #F77963 !important
		color: white!important

	.description-bull2
		background: #F77963 !important
		color: white!important

.isDeprecated:hover > .description-bull
	opacity: 1 !important

.isDeprecated:hover > .description-bull2
	opacity: 1 !important


.field-ctn
	color: var(--inputTextColor)
	padding-right:0px

	p.text-hover
		display: none

	span.text
		display: inline-block
		width: 125px
		text-align: left
		float:left
		height: 40px
		line-height: 40px
		vertical-align: middle

	.input-fields
		width: 235px
		float: right
		text-align: right
		height: 40px
		margin-right: 5px

		.textinput-ctn
			input.value.vector3D.x, input.value.euler.alpha
				margin-left: 0px
				width: 41px !important
		input.value.vector3D.y, input.value.euler.beta
				margin-left: 5px
				width: 42px !important
			input.value.vector3D.z, input.value.euler.gamma
				margin-left: 5px
				width: 42px !important
	.plug
		width: 30px
		margin-top: 5px
		margin-left:5px
		display: inline-block

.field-ctn:hover > .description-bull
	opacity: 1 !important

.field-ctn:hover > .description-bull2
	opacity: 1 !important
.field-ctn:hover > .text-hover
	&.isTooLong
		position: absolute
		display: block
		z-index: 1
		border: 2px solid #f49e75
		border-radius: 4px
		bottom: 19px
		left: 37px
		background-color: #fff6ec
		padding: 4px 4px

.accordion .form-content .form-accordion .title:hover > .field-ctn > .description-bull
	opacity: 1 !important

.accordion .form-content .form-accordion .title:hover > .field-ctn > .description-bull2
	opacity: 1 !important

.accordion .form-content .form-accordion .title:hover > .description-bull
	opacity: 1 !important

.accordion .form-content .form-accordion .title:hover > .description-bull2
	opacity: 1 !important

.accordion .form-content .form-accordion .title
	.description-bull
		background: var(--bgColor)
		position: absolute
		margin-top: 8px
		margin-left: -138px
		width: 24px
		height: 24px
		line-height: 28px
		border-radius: 20px
		text-align: center
		color: var(--selectedColor)
		cursor: pointer
		opacity: 0
		font-family: 'DinBold'
		font-size: 18px
		transition: linear opacity .1s
		box-shadow: 0 0 5px rgba(0,0,0,.1)
	.description-bull2
		background: var(--bgColor) !important
		position: absolute
		margin-top: -33px
		margin-left: -19px
		width: 24px
		height: 24px
		line-height: 28px
		border-radius: 20px
		text-align: center
		color: var(--selectedColor)
		cursor: pointer
		opacity: 0
		font-family: 'DinBold'
		font-size: 18px
		transition: linear opacity .1s
		box-shadow: 0 0 5px rgba(0,0,0,.1)
.field-group
	.field-ctn
		/*margin-top: 5px
		margin-bottom: 5px
		height: 40px*/
		position: relative
		background: var(--inspectorTargetColor)
		margin: 5px
		border-radius: 4px
		transition: linear all .1s !important
		&:hover
			background: var(--inspectorTargetOverColor)

	.field-ctn.field
		.description-bull
			background: var(--bgColor)
			position: absolute
			margin-top: 8px
			margin-left: -28px
			width: 24px
			height: 24px
			line-height: 28px
			border-radius: 20px
			text-align: center
			color: var(--selectedColor)
			cursor: pointer
			opacity: 0
			font-family: 'DinBold'
			font-size: 18px
			transition: linear opacity .1s
			box-shadow: 0 0 5px rgba(0,0,0,.1)
			&.buildOnly
				background: #f77963 !important
				color: white
		.description-bull2
			background: var(--bgColor) !important
			position: absolute
			margin-top: -33px
			margin-left: -19px
			width: 24px
			height: 24px
			line-height: 28px
			border-radius: 20px
			text-align: center
			color: var(--selectedColor)
			cursor: pointer
			opacity: 0
			font-family: 'DinBold'
			font-size: 18px
			transition: linear opacity .1s
			box-shadow: 0 0 5px rgba(0,0,0,.1)

.field-wrapper .param-ctn .textinput-ctn.has-unit .unit
	margin-top: 1px
	text-align: left
	margin-right: -70px
	margin-top: -2px
	width: 55px
	line-height: 30px

.textinput-ctn
	display: inline-block
	width: 165px
	height: 40px
	vertical-align: top
	position: relative
	line-height: 40px
	margin:0
	margin-left: -5px

	input.value
		width: calc(100% - 15px)
		height: 30px
		margin: 0
		margin-left: 5px
		margin-top: 5px
		padding-left: 10px
		color: #707070
		font-family: 'DinBold'
		font-size: 14px
		border-radius: 4px
		outline: none!important

		&.uint
			width: 70px
			line-height: 15px
			border:none

		&:disabled
			opacity: 0.2

	.edit-bt.disabled
		opacity: 0.2
		cursor: default

	&.has-unit

		input.value
			width: 126px
			padding-right: 25px
			margin-left: 5px
			border-radius: 4px

	.unit
		/*width: 16%
		position: absolute
		right: 5px
		top: 0px
		line-height: 26px
		font-size: 10px
		text-align: center
		display: inline-block*/
		width: 51px
		position: absolute
		right: 0px
		top: 0px
		text-align: left
		line-height: 30px
		font-size: 14px
		font-family: 'DinBold'
		background-color: var(--inputValue)
		border-radius: 2px
		height: 30px
		margin-top: 5px
		padding-left: 10px
		border-radius: 4px

.param-ctn

	.textinput-ctn.array-bt
		width: 100%

.legend
	visibility: hidden
	display: inline-block
	position: absolute
	top: 0
	right: 0
	width: 235px
	margin-top: 0
	line-height: 40px
	font-size: 14px
	opacity: .6
	color: #707070
	span
		display: inline-block
	span#legend-default
		position: absolute
		left: 0
	span#legend-in
		position: absolute
		left: 95px
	span#legend-out
		position: absolute
		left: 130px

.save-zone
	.form-content
		padding-top: 0px
.save-bt, .edit-bt
	width: 100%
	margin: auto
	font-family: 'DinRegular', 'ComfortaaLight'
	font-size: 13px
	line-height: 40px
	text-align: center
	background-color: var(--saveColor)
	color: white
	border-radius: 2px
	cursor: pointer

	span.text, span.label
		display: inline-block
		line-height: 40px
		vertical-align: middle
		color: white
		padding-left: 10px
		padding-right: 10px
		font-size:14px
		margin-top: -10px

.save-bt
	font-family: 'DinBold', 'ComfortaaLight'
	font-size: 16px
	border-radius: 40px
	margin-bottom: 10px
	transition: linear all .1s !important;
	&:hover
		background: var(--selectedSaveColor)
	.text:hover
		color: var(--selectedSaveTextColor)!important

.param-ctn
	.edit-bt
		line-height: 26px
		font-size: 12px
		width: 160px
		margin-right: 0px !important
		span.label
			line-height: 30px
			margin-top: 0
			font-family: 'DinBold'
.input-fields
	.edit-bt
		margin-top: 5px
		width: calc(100% - 10px)
		margin-left: 5px
		font-size: 14px
		height: 30px
		background: #ffffff
		border-radius: 4px
		font-family: 'DinBold'
		.label
			color: #707070

.inspector-panel.memory
	.field-ctn.param
		position: static;
	.geomodal
		position: fixed
		right: 287px
		top: 399px
		.mapboxgl-canvas
			margin-left: 14px

.inspector-panel.obj.panel-transition.memory
	.accordion .form-content .form-accordion .title
		display: flex
		justify-content: space-between


.inspector-panel.obj.panel-transition.session-set
	.accordion.topic
		.form-content.open
			.children
				.field-ctn.param
					.description-bull2
						background-color: var(--bgColor) !important
						opacity: 0
						position: absolute
						margin-top: 8px
						margin-left: -12px
						width: 24px
						height: 24px
						line-height: 28px
						border-radius: 20px
						text-align: center
						color: var(--selectedColor)
						cursor: pointer
						opacity: 0
						font-family: 'DinBold'
						font-size: 18px
						transition: linear opacity 0.1s
						box-shadow: 0 0 5px rgba(0,0,0,0.1)

.mapboxgl-map
	margin-left: -10px
	margin-top: -5px
	margin-bottom: -5px
	top: -5px
	right: -5px
	border-radius: 4px

.uiSelector
	margin-top: 0px
	margin-bottom: 0px
	.field-ctn
		height: 28px
		margin-bottom: -9px
		span.text
			width: 102px
		.input-fields.groupfield
			width: 230px !important
			.object.textinput-ctn
				width: 235px
				select
					padding: 0 10px
					width: 225px !important
					background: #fcf2e9

.uiSelector .field-ctn
	background: var(--inspectorTargetOverColor) !important
	height: 40px !important

.title.tab-title .col1
	margin-left: 0px!important

.uiSelector
	margin-top: -5px!important

	.field-ctn
		background: transparent
		height: 30px

		.input-fields.groupfield
			.object.textinput-ctn select
				background: var(--inputValue)
				margin-top: 5px!important
				margin-right: 0px!important

// Color Picker

.vc-chrome
	background: var(--bgColor) !important
	border-radius: 8px !important
	box-shadow: 0 0px 10px var(--shadowColor) !important
	box-sizing: initial !important
	width: 225px !important
	font-family: DinRegular !important
	padding: 10px !important
	width: calc(100% - 10px)!important
	margin-right: 0px !important

	.vc-chrome-saturation-wrap
		border-radius: 4px

	.vc-chrome-body
		padding: 10px 0 0 0

// Doc

.help
	.icon
		background: var(--bgColor)
		color: var(--inputTextColor)
		width: 36px !important
		height: 36px !important
		position: absolute
		top: 30px
		right: 10px
		font-size: 20px
		text-align: center
		line-height: 38px
		font-family: 'DinBold'
		border-radius: 30px
		box-shadow: 0 0 5px rgba(0,0,0,.3)
		transition: linear opacity .1s
		&:hover
			background: var(--selectedColor)
			color: #ffffff
	.text
		background: var(--bgColor)
		padding: 10px
		position: absolute
		top: 56px
		right: 5px
		width: 200px
		border-radius: 8px
		color: var(--inputTextColor)
		font-size: 14px
		line-height: 18px
		z-index: 30
		box-shadow: 0 0 10px var(--shadowColor)
		font-family: 'DinRegular'
		transition: linear opacity .2s
		opacity: 0
		visibility: hidden
		&:before
			top: -18px
			left: 197px
			border: solid transparent
			content: " "
			height: 0
			width: 0
			position: absolute
			pointer-events: none
			border-color: rgba(255, 255, 255, 0)
			border-bottom-color: var(--bgColor)
			border-width: 10px
			margin-left: -10px

.help .icon:hover ~ .text
	opacity: 1
	visibility: visible

.docLink
	background: var(--bgColor)
	width: 24px
	height: 24px
	border-radius: 20px
	margin-top: -34px
	color: var(--bgColor) !important
	position: absolute
	right: 20px
	text-align: center
	transition: linear opacity .1s
	opacity: 0
	&:hover
		background: var(--selectedColor)
		color: #ffffff
		opacity: 1
	&:hover > a
		background: var(--selectedColor)
		color: #ffffff
		opacity: 1
	a
		color: var(--mainColor)
		text-decoration: none
		font-size: 16px
		font-family: 'DinBold'
		line-height: 28px
		vertical-align: top
		transition: linear opacity .1s
		&:hover
			background: var(--selectedColor)
			color: #ffffff
			opacity: 1


</style>
