<!-- ArrayRow Fields vue -->

<template lang="pug">

div.object-includer(v-bind:class="queryMode")
	div(v-if="subInspector && subtarget")
		div(v-for="bloc of subInspector.children")
			sub-topic(v-if="bloc.type=='topic'", :project="project" , :target="subtarget", :bloc="bloc", :state="state", :inspectorField='inspectorField' )


</template>

<script>

import * as helper from '../../../helper';
import actionManager from '../../../actions';

import SubTopic from "../blocs/Topic.vue";

export default {

	name: 'object-includer',

	props:[ 'project', 'target', 'bloc', 'state','inspectorField' ],

	components: { SubTopic },

	data() {
		return {
			subtarget : null,
			prevObjectId : null,
			prevTypeId: null,
			init : true
		}
	},

	computed: {

		'mode': function () {
			let f = helper.block.getField(this.target, 'moduleMode');
			if (f)
				return f.value;
			else
				return "update"
		},

		'objectId': function(){
			if ( this.mode === 'create' || ( this.mode === 'update' && this.queryMode === 'query') ) {
				return null;
			}

			let f = helper.block.getField(this.target, this.bloc.target);
			if (f) {
				let uiId = helper.block.getField(this.target, this.bloc.target).value;

				return uiId;
			} else {
				return null;
			}

		},

		'object': function(){
			if (this.mode === 'create' || ( this.mode === 'update' && this.queryMode === 'query') ) {
				return null;
			}

			let obj = null;

			if( this.objectId != null ){

				obj = helper.block.getBlockById( this.project , this.objectId );

				if( !obj || ( obj && obj.value._deleted ) ){

					actionManager.trigger('main:modal:openModal', {title:'Object not found', text:'An error occured, the included object has been deleted. You need to select a new one.', continueButton: 'OK', cancelButton: '', icon: 'notfound' })

					let f = helper.block.getField(this.target, this.bloc.target);
					actionManager.trigger('inspector:updateValue', { subaction: this.target.value.type, targetId: this.target.value._id, fieldName: f.name, newValue: null } )

					return null;
				}
			}

			return obj;
		},

		'queryMode': function(){
			let queryMode = false;
			let queryModeField = helper.block.getField(this.target, "moduleQueryMode");
			if( this.mode === "update" && queryModeField && queryModeField.value === "query" )
				queryMode = true;
			actionManager.trigger('inspector:updateQueryMode', { queryMode : queryMode });
			return queryModeField ? queryModeField.value : null;
		},

		'type': function(){
			let queryType;
			let targetType;
			let queryMode = helper.block.getField(this.target, "moduleQueryMode");

			if(helper.block.getField(this.target, "moduleQueryType")){
				queryType = helper.block.getField(this.target, "moduleQueryType").value;
			}

			if( helper.block.getField(this.target, this.bloc.targetType)){
				targetType =  helper.block.getField(this.target, this.bloc.targetType).value;
			}

			//targetType = helper.config.mapConfigConvertion( targetType , this.$xpConfig["mapLibrary"] );

			if (this.mode !== 'create') {
				if (queryMode && queryMode.value === "query") {
					return queryType;
				} else {
					return null;
				}
			} else {
				return targetType;
			}
		},

		'subInspector': function () {

			let subInspector = null;
			let includedObj = null;

			if( this.object ){
				let obj = this.object;
				if( obj.custom.fields == null )
					actionManager.trigger('block:updateFields', { id : obj.value._id });

				let format = helper.config.mapConfigConvertion( obj.value.format , this.$xpConfig["mapLibrary"] )
				let objInspector = helper.config.getConfigByType( this.$config.authoring , obj.value.type, format );

				subInspector = objInspector.value.inspector;
				if (this.prevObjectId !== obj.value._id) {
					actionManager.trigger('block:updateIncludedBlocks', { id : this.target.value._id, includedBlocks : [  obj.value._id ], includedInspector : null  });
					this.prevObjectId = obj.value._id;
				}

				this.subtarget = this.target; // obj;

				includedObj = obj.value._id;
			}
			else if (this.type) {

				let objInspector = helper.config.getConfigByType( this.$config.authoring , 'object', helper.config.mapConfigConvertion( this.type , this.$xpConfig["mapLibrary"] ) );

				if (objInspector) {
					subInspector = objInspector.value.inspector;
				}

				let defaultVal = this.queryMode == "query" ? false : true;

				if (this.prevTypeId !== this.type) {

					//!\ deprecated : includedInspector
					let includedInspector = null;
					if( this.target.value.includedInspector ){
						includedInspector = [ subInspector ];
					}

					//actionManager.trigger('block:updateIncludedBlocks', { id : this.target.value._id, includedBlocks : null, includedInspector : [ subInspector ] , defaultVal : defaultVal });
					actionManager.trigger('block:updateIncludedBlocks', { id : this.target.value._id, includedBlocks : null, includedBlockType : [ this.type ] , includedInspector : includedInspector, defaultVal : defaultVal });
					this.prevTypeId = this.type;
				}

				this.subtarget = this.target;
				//includedObj = null;

			} else {
				if (this.prevTypeId !== this.type || this.prevObjectId !== this.objectId) {
					actionManager.trigger('block:updateIncludedBlocks', { id : this.target.value._id, includedBlocks : null, includedInspector : null });
					this.prevObjectId = this.objectId;
					this.prevTypeId = this.type;
				}
			}


			this.initSubInspector( subInspector , includedObj );
			return subInspector;

		}
	},

	watch: {

	},

	created: function(){
		let currentVal = helper.block.getField(this.target, this.bloc.target).value;
		this.prevObjectId = currentVal ? currentVal : null;
		// Computed property "objectId" was assigned to but it has no setter (since migration Vue v1 to v2)
		// Does this make sens to assigned this property ?
		//this.objectId = currentVal ? currentVal : null;

		if( this.type ){
			this.prevTypeId = this.type;
		}
		else{
			let typeVal = helper.block.getField(this.target, this.bloc.targetType);
			this.prevTypeId = typeVal ? typeVal.value : null;
			// Computed property "type" was assigned to but it has no setter (since migration Vue v1 to v2)
			// Doe this make sens to assigned this property ?
			//this.type = typeVal ? typeVal.value : null;
		}

	},

	destroyed: function(){

	},

	methods:{

		initSubInspector: function( inspector , includedObj ){

			if(!inspector)
				return;

			for( let child of inspector.children ){

				if( ( child.type == "field" || child.type == "param" ) && includedObj )
					child.includedField = includedObj;

				if( child.type == "field" && child.widget == "repeated-form" ){
					helper.block.constructRepeatedForm( child );
				}

				if( child.type == "field" && child.widget == "dynamic-fields" ){
					helper.block.constructDynamicFieldsGroup(child);
				}


				if( child.children && child.children.length > 0 ){
					this.initSubInspector( child , includedObj );
				}

			}

		},

	},

}

</script>

<style lang="stylus">

.array-row-fields
	//padding: 8px 4px 8px 8px

.object-includer

	&.query
		.field-group .field-ctn
			position relative


</style>
