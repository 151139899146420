import deepCopy from 'deepcopy';

import { default as helperBlock } from './block';
import { default as helperConfig } from './config';

const links = {

    getIndexOfLink: function( block, link ){
        let linkIndex = -1;
        let cpt = 0;
        for( let linkOfBloc of block.value.links ){
            if( link.from.blocID == linkOfBloc.from.blocID && link.from.name == linkOfBloc.from.name && link.to.blocID == linkOfBloc.to.blocID  && link.to.name == linkOfBloc.to.name ){
                linkIndex = cpt;
            }
            cpt++;
        }
        return linkIndex;
    },

    checkLink: function ( from, bloc1, to, bloc2, linkData, testExists = true, testCastConsistency = false, state = null ) {

        let isLinkExist = ( bloc1 , fieldFrom, bloc2 , fieldTo ) => {
                if( bloc1.value.links && bloc1.value.links.length > 0 ){
                    for (var i = 0; i < bloc1.value.links.length; i++) {
                        if( bloc1.value.links[i].from.name == fieldFrom && bloc1.value.links[i].to.name == fieldTo && bloc1.value.links[i].to.blocID  ==  bloc2.value._id )
                            return true;
                    }
                }
                return false;
            },
            isCastAuto = (linkData) => {
                if (this.getInputSide(linkData).type === 'state' && this.getOutputSide(linkData).type === 'state')
                    return true;

                let optionsAvailable = this.getCastOptions(linkData, state);
                return typeof optionsAvailable === 'string' ? true : false;
            },
            isCastConfigured = (linkData, blocFrom) => {
                let optionsAvailable = this.getCastOptions(linkData, state);

                if (!optionsAvailable) {
                    return false;
                }
                let linkChoosenConvertion = blocFrom.value.links[this.getIndexOfLink(blocFrom, linkData)].conversion;
                if (typeof optionsAvailable === 'string' || optionsAvailable.indexOf(linkChoosenConvertion) !== -1) {
                    return true;
                }
            },
            getConnection = (bloc, cnxName) => {
                let valueFields = {};
                bloc.value.fields.forEach((f) => {
                    valueFields[f.name] = f;
                });

                let valueStates = {};
                // specific to triggers and screens ?
                if (bloc.value.states) {
                    if (bloc.value.states.in) {
                        bloc.value.states.in.forEach( (state) => {
                            valueStates[state] = {in: true};
                        });
                    }
                    if (bloc.value.states.out) {
                        bloc.value.states.out.forEach( (state) => {
                            valueStates[state] = Object.assign(valueStates[state] || {}, {out: true});
                        });
                    }
                }
                let connections = Object.assign({},
                                                valueStates,
                                                bloc.custom.states, 
                                                bloc.custom.fields, 
                                                valueFields
                                                );

                return connections[cnxName];
            },
            isDisplayed = ( bloc , name ) => {
                if( bloc.custom.fields && bloc.custom.fields[name] ){
                    return bloc.custom.fields[name].display;
                }
                return false;
            }

        if (bloc1.value._deleted || bloc2.value._deleted)
            return false;

        if( from.type === 'field' && to.type === 'field' && (!isDisplayed( bloc1 , from.name ) || !isDisplayed( bloc2, to.name )) )
            return false; // One of the plug is not displayed anymore
        
        if( from.side == to.side )
            return false; // Deux liens ne peuvent pas être du meme coté (output to output ou input to input)

        let fromField = getConnection(bloc1, from.name),
            toField = getConnection(bloc2, to.name);
        
        let fromSide = from.side == 'output' ? 'out' : 'in';
        let toSide = to.side == 'output' ? 'out' : 'in';
        if ( !fromField || !toField || !fromField[fromSide] || !toField[toSide] )
            return false;

        if( testCastConsistency && !isCastAuto( linkData ) && !isCastConfigured( linkData, bloc1 /* bloc from */ ) )
            return false;

        if( testExists && (isLinkExist( bloc1, from.name , bloc2 , to.name ) || isLinkExist( bloc2, to.name, bloc1, from.name )) )
            return false;
            
        return true;
    },
    isCastAvailable: function( config, inputType , outputType ){

        let conversion = false;

        let capitalizeFirstLetter = (string) => {  return string.charAt(0).toUpperCase() + string.slice(1);  }

        inputType = capitalizeFirstLetter( inputType );
        outputType = capitalizeFirstLetter( outputType );


        // TEMP FIX
        inputType = (inputType == "Number") ? 'Float' : inputType;
        outputType = (outputType == "Number") ? 'Float' : outputType;

        inputType = (inputType == "Object") ? 'DataObject' : inputType;
        outputType = (outputType == "Object") ? 'DataObject' : outputType;

        inputType = (inputType == "Bool") ? 'Boolean' : inputType;
        outputType = (outputType == "Bool") ? 'Boolean' : outputType;
        
        // exception  for composite types (JSONType, GeoShape, 2DShape), 
        // ticket (NS-1201) https://orbe.atlassian.net/browse/NS-1201
        inputType = (inputType.toLowerCase() == "jsontype") ? 'JSONType' : inputType;
        outputType = (outputType.toLowerCase() == "jsontype") ? 'JSONType' : outputType;
        inputType = (inputType.toLowerCase() == "geoshape") ? 'GeoShape' : inputType;
        outputType = (outputType.toLowerCase() == "geoshape") ? 'GeoShape' : outputType;
        inputType = (inputType.toLowerCase() == "shape2d") ? 'Shape2D' : inputType;
        outputType = (outputType.toLowerCase() == "shape2d") ? 'Shape2D' : outputType;

        let defaultConv = outputType +" to "+ inputType;
        
        
        if( inputType == "Mixed" || outputType == "Mixed" || inputType == "DataObject" || outputType == "DataObject" )
            conversion = defaultConv;
        
        if( inputType == outputType )
            conversion = "";

        let conversionProps = null;
        /*if( this.main.config.datatypes[outputType] )
            conversionProps = this.main.config.datatypes[outputType]['to'+inputType];*/

        if( config[outputType] )
            conversionProps = config[outputType]['to'+inputType];

        if( conversionProps && conversionProps.length >= 1 )
            conversion = conversionProps;
        else if( conversionProps && conversionProps.length == 0 )
            conversion = defaultConv;




        return conversion;
    },
    getAnchorCoordinates: function( blocId, side , fieldname ){

        let blocDOM = document.getElementById('bloc-'+blocId);
        if( !blocDOM )
            return null;

        let fieldDOM = blocDOM.getElementsByClassName( side+' '+fieldname)[0];
        if( !fieldDOM )
            return null;

        let anchorDOM = fieldDOM.getElementsByClassName('anchor')[0];
        if( !anchorDOM )
            return null;

        return {
            x: fieldDOM.offsetLeft + anchorDOM.offsetLeft + 4,
            y: fieldDOM.offsetTop + anchorDOM.offsetTop
        };

    },

    getCastOptions: function (link, state) {

        let inputInsp = this.getInspector( this.getInputSide( link ), state );
        let outputInsp = this.getInspector( this.getOutputSide( link ), state );

        let inputFormat = this.getFormat( inputInsp );
        let outputFormat = this.getFormat( outputInsp );

        let conversion = '';

        if( inputFormat && outputFormat ){
            conversion = this.isCastAvailable( state.dataTypes, inputFormat, outputFormat );
        }

        return conversion;
    },

    getConversions: function ( conversionList ){
        let conversions = [];

        if( typeof conversionList === "string" && conversionList != "" ){
            conversions.push( conversionList+" (auto)");
        }
        else{
            conversions = conversionList;
        }

        return conversions;
    },

    getActivation: function ( inputInsp ){

        let isActivable = false;
        let forceActivable = false;
        if( inputInsp && inputInsp.connection && inputInsp.connection.activate ){
            let activateConfig = inputInsp.connection.activate;

            if( activateConfig.pluggable)
                isActivable = true;

            if( activateConfig.default && activateConfig.pluggable ){
                isActivable = true;
            }

            if( activateConfig.force ){
                forceActivable = true;
                isActivable = true;
            } 
        }
        
        return { forceActivable : forceActivable , isActivable : isActivable };
    },


    getInputSide: function ( link ){
        if(link.from.side == "input")
            return link.from;
        return link.to;
    },

    getOutputSide: function ( link ){
        if(link.from.side == "output")
            return link.from;
        return link.to;
    },


    getFormat: function ( insp ){
        // return DataObject for state plugs
        if( !insp || insp.type == 'state' ) return "DataObject";
        // allow input/ ouput link for select field in repeated-form
        if (insp.widget && insp.widget.toLowerCase() === 'select' && !insp.coreFormat && insp.type) return insp.type
        return insp.coreFormat ? insp.coreFormat : insp.widget;
    },

    getInspector:function ( field, state ){

        let bloc = helperBlock.getBlockById( state.project , field.blocID);

        if( field.type === 'state' ) {
            let format;
            const config = helperConfig.getConfigByType( state.config, bloc.value.type, format );
            if(config.value && config.value.autoLinkActivation) {
                inspector = config.value.autoLinkActivation
                return inspector;
            } else {
                return null;
            }
        }

        let blocInspector = helperConfig.getMergedInspector( state, bloc, state.xpConfig.mapLibrary );

        let fieldName = field.repeatedField ? field.parentField : field.name;

        let inspector = helperConfig.findByPropInInspector( blocInspector, fieldName , 'name' );

        // double check for repeatedFields
        if( inspector === null ){
            let fieldNameSplited = fieldName.split('_');
            if( fieldNameSplited.length-1 >= 2 ){ // more than 2 underscore in the name, it may be a repeatedfield
                inspector = helperConfig.findByPropInInspector( blocInspector, fieldNameSplited[0] , 'name');

                if( inspector && inspector.form )
                    inspector = inspector.form[fieldNameSplited[2]];
            }
        }

        // again for dynamic fields
        if (inspector === null && bloc.custom.dynamicFields) {
            inspector = bloc.custom.dynamicFields[fieldName];
        }

        // triple check = is this is a col from an array row ?
        if( inspector === null ){
            // get info of the array row module configuration
            let arrayRowField = helperConfig.findByPropInInspector( blocInspector, "array-row-field", 'type' );
            
            // to know which property is the target of the array 
            let arrayField = helperBlock.getField( bloc, arrayRowField.target );
            // and get the array
            let array = helperBlock.getArrayRowCols( state.project, arrayField.value );
            // get the number of the current column with the field name formated like idMemory+"_"+numCol
            let numCol;
            if (arrayRowField.mode == "rows") {
                let targetCol = helperBlock.getField( bloc, arrayRowField.target_col );
                numCol = targetCol.value;
            }else{
                numCol = parseInt( field.name.replace(arrayField.value+'_', '') );
            }
            if( array && array.header && Array.isArray( array.header ) && numCol !== NaN && array.header[numCol] ){
                // define the widget and type is usef for this column
                inspector = array.header[numCol];
                inspector.connection = bloc.custom.fields[field.name].connection;
            }
        }

        // if this is a dynamic type (type changes function to an other field)
        if( inspector && inspector.dynamicType ){

            let dynTypeFieldName = inspector.dynamicType.field;

            if(inspector.dynamicType.field === "memoryId" && inspector.dynamicType.deprecated === false) {

                let memoryId = bloc.custom.fields[inspector.dynamicType.field].value;
                let memory = helperBlock.getEntityByIDInWholeProject(state.project, memoryId );
                let memoryFormat = memory.value.format

                if (memoryFormat === "array"){
                    let getColFieldIndex = helperBlock.getField( bloc, 'column' )
                    let memoryValueField = helperBlock.getField(memory, 'value')

                    if(getColFieldIndex && memoryValueField) {
                        let indextoget;
                        if(getColFieldIndex.value) {
                            indextoget = getColFieldIndex.value
                        } else {
                            indextoget = 0
                        }
                        if (indextoget < 0 || indextoget >= memoryValueField.header.length) {
                            indextoget = 0
                        }

                        let colFormat = memoryValueField.header[indextoget].widget
                        function capitalizeFirstLetter(string) {
                            return string.charAt(0).toUpperCase() + string.slice(1);
                        }
                        inspector.coreFormat = capitalizeFirstLetter(colFormat);
                    }
                    
                } else {
                    inspector.coreFormat = memoryFormat;
                }
            } else {
                let prop = inspector.dynamicType.property;
            
                let newInspectorField = helperConfig.findByPropInInspector( blocInspector, dynTypeFieldName, 'name' ); 
                let dynTypeField = helperBlock.getField( bloc, dynTypeFieldName );
                inspector = null;
            
                if( prop ){
                    // a sub property is define, that means dynamic test field is an object, we need to get it a get the property value to get the type
                    let entity = helperBlock.getEntityByIDInWholeProject( state.project, dynTypeField.value );
                    
                    if( entity && entity.value )
                        entity = entity.value;
            
                    if( entity && entity[prop] ){
                        inspector =  { coreFormat: entity[prop] };
                    }
                }
                else{
                    // get value of the selected dynamic type as name for the current type
                    inspector =  { coreFormat: dynTypeField.value }; 
                }
            }
        }

        return inspector;

    }
}

export default links;