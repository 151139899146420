<!-- Select vue -->

<template lang="pug">

span.textinput-ctn
	span.select-arrow &gt;
	select(v-on:change="valueUpdated(field)", v-model="value", :disabled="field.disabled")
		option(v-if="!isGrouped", v-for="option in options", v-bind:value="option.value") {{ option.text }}
		optgroup(v-if="isGrouped", v-for="(group, name) of options" :label="name")
			option(v-for="option in group" :value="option.value") {{ option.text }}
</optgroup>

</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'placeholder' ],

	data() {
		return {
			value: null,
			actionOnChange: false,
			actionOnChangeInited: false,
			isGrouped: false,
		}
	},

	computed : {
		'options': function(){
			if (this.actionOnChange && !this.actionOnChangeInited && this.value && this.field.options.length) {
				this.$parent.dynAction(this.actionOnChange, this.value);
				this.actionOnChangeInited = true;
			}

			const groups = {};
			let options = this.field.options.map( (option) => {
				if (option.group) groups[option.group] = [];
				option.text = option.label;
				if (option.deprecated) option.text += " (deprecated)";
				if (option.value == this.placeholder) option.text += ' (default)';
				return option;
			});

			this.isGrouped = Object.keys(groups).length !== 0;
			if (this.isGrouped) {
				options.forEach((option) => groups[option.group].push(option));
				return groups;
			}

			return options;
		}

	},

	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : null;

		if( !this.value && this.placeholder )
			this.value = this.placeholder;

		if (this.field.action !== undefined) {
			this.field.action.forEach((action) => {
				if (action.on === 'change') this.actionOnChange = action;
				if (action.on === 'load') this.$parent.dynAction(action, this.value);
			});
		}
	},

	destroyed: function(){

	},

	watch:{
		'value': function(newVal, oldVal){
			this.selectHasChanged(newVal)
		}
	},

	methods:{
		selectHasChanged: function(newVal){
			this.$parent.selectHasChanged(newVal);
		},

		valueUpdated: function( fieldUpdated ){
			if (this.actionOnChange && this.value !== this.fieldvalue.value) {
				this.$parent.dynAction(this.actionOnChange, this.value);
				this.actionOnChangeInited = true;
			}
			this.$parent.valueUpdated( fieldUpdated, this.value);
		}
	}
}

</script>

<style lang="stylus">
</style>
