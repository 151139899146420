import deepcopy from "deepcopy";

let _blockCache = {}

let checkPathConsistency = (cacheResult, base) => {
    let ref = cacheResult[0],
        foundNode = base,
        pathParts = cacheResult[1].split('.');

    try {
        for(let i=0; i<pathParts.length; i++) {
            let indexOf = pathParts[i].indexOf('['), arrayIndex = -1;
            if (indexOf !== -1) {
                arrayIndex = parseInt(pathParts[i].substr(indexOf + 1, (pathParts[i].indexOf(']') - indexOf) - 1), 10);
                pathParts[i] = pathParts[i].substr(0, indexOf);
            }
            if (arrayIndex !== -1)
                foundNode = base[pathParts[i]][arrayIndex];
            else
                foundNode = base[pathParts[i]];
            base = foundNode;
        }
    } catch(e) {
        return false;
    }

    if (foundNode === ref) {
        return true;
    } else {
        return false;
    }
}

const block = {
    getBlockById: function( node , id, path = false ){

        let returnValue = [
            null,           // searched object's parent
            ''              // path
        ];


        let cache;
        cache = _blockCache[node.value._id + '_' + id];
        if (cache && checkPathConsistency(cache, node)) {
            returnValue = cache;
        } else {
            delete _blockCache[node.value._id + '_' + id];
        }

        if (!returnValue[0]) {
            if( node.value._id == id ) {
                returnValue[0] = node;
            } else {
                // go through block children
                if( node.custom.children && node.custom.children.length > 0 ){
                    for( let i = 0; i < node.custom.children.length; i++ ){
                        let subnode = node.custom.children[i];
                        returnValue = this.getBlockById( subnode , id, returnValue[1] );
                        if( returnValue[0] ) {
                            returnValue[1] =  "custom.children[" + i + "]" + (returnValue[1] === '' ? '' :  "." + returnValue[1]);
                            break;
                        }
                    }

                }
                // go through objects children
                if(!returnValue[0] && node.custom.objects && node.custom.objects.length > 0 ){
                    for( let i = 0; i < node.custom.objects.length; i++ ){
                        let subnode = node.custom.objects[i];
                        returnValue = this.getBlockById( subnode , id, returnValue[1] );
                        if( returnValue[0] ) {
                            returnValue[1] =  "custom.objects[" + i + "]" + (returnValue[1] === '' ? '' :  "." + returnValue[1]);
                            break;
                        }
                    }
                }

                // go through templates document
                if (!returnValue[0] && node.value.template && node.value.template.indexOf(id) != -1) {
                    returnValue = this.getTemplateById(node,id,true);
                }

                // go through template children/objects
                if (!returnValue[0] && node.templates && node.templates.length > 0) {
                    for (let i = 0; i < node.templates.length; i++) {
                        let subnode = node.templates[i];
                        returnValue = this.getBlockById(subnode, id, returnValue[1]);
                        if (returnValue[0]) {
                            returnValue[1] = "templates[" + i + "]" + (returnValue[1] === '' ? '' : "." + returnValue[1]);
                            break;
                        }
                    }
                }
            }

            _blockCache[node.value._id + '_' + id] = returnValue;
        }

        if (path === false) {
            if( returnValue[0] )
                return returnValue[0];
            else
                return null;
        } else {
            return returnValue;
        }
    },

    /**
     * Returns all blocks referring to a specified template.
     * @param {Object} node - The current project or node to search inside.
     * @param {boolean} id - The template ID.
     * @returns {Array} A list of all blocks found.
     */
    getBlocksByTemplateId: function( node, id ) {
        let blocks = [];
        // go through block children
        if (node.custom.children && node.custom.children.length > 0) {
            for (let i = 0; i < node.custom.children.length; i++) {
                let block = node.custom.children[i];
                if (block.value.ref_template && block.value.ref_template == id) blocks.push(block);
                if (block.custom.children && block.custom.children.length > 0) blocks = blocks.concat(this.getBlocksByTemplateId(block, id));
            }
        }

        return blocks;
    },

    getMemoryOwner: function ( baseNode, memId, getPath = false ) {

        let blocIsMemoryOwner = (bloc) => {
                let index = bloc.value.memory.findIndex((localMem) => {
                    return localMem.id === memId;
                });
                return index !== -1;
            },
            path = '';

        if (blocIsMemoryOwner(baseNode)) {
            return !getPath ? baseNode : [baseNode, path];
        } else {
            if (baseNode.custom.children) {
                for (let childId=0; childId< baseNode.custom.children.length; childId++) {
                    let child = baseNode.custom.children[childId];
                    let returnedValue = this.getMemoryOwner(child, memId, getPath);
                    if (returnedValue !== undefined) {
                        if (getPath) {
                            returnedValue[1] = returnedValue[1] + '.custom.children[' + childId + ']';
                        }
                        return returnedValue;
                    }
                }
            }

            // search in templates
            if (baseNode.templates && baseNode.templates.length > 0) {
                for (let i = 0; i < baseNode.templates.length; i++) {
                    let returnedValue = this.getMemoryOwner(baseNode.templates[i], memId, getPath);
                    if (returnedValue !== undefined) {
                        if (getPath) {
                            returnedValue[1] = "templates[" + i + "]" + returnedValue[1];
                        }
                        return returnedValue;
                    }
                }
            }
        }
    },

    getMemoryById: function (node, idMemory, returnPath = false) {
        // search in node
        if (node.memory && node.memory.length > 0 ){
            for (let i = 0; i < node.memory.length; i++ ) {
                let memory = node.memory[i];
                if( memory.value._id == idMemory ){
                    return returnPath ? [memory, 'memory[' + i + ']'] : memory;
                }
            }
        }
        // search in node templates
        if (node.templates && node.templates.length > 0) {
            for (let i = 0; i < node.templates.length; i++) {
                let memory = this.getMemoryById(node.templates[i], idMemory, true );
                if (memory) return returnPath ? [memory[0], 'templates[' + i + ']' + '.' + memory[1]] : memory[0];
            }
        }
    },

    /**
     * Search for the specified template in project.
     * @param {Object} project - The project tree.
     * @param {string} idTemplate - The template ID.
     * @param {boolean} returnPath - Whether path must be returned or not.
     * @returns {(Object|Array)} the template object or an Array containing in order the template object and path
     */
    getTemplateById: function (project, idTemplate, returnPath = false) {
        if (project.templates && project.templates.length > 0) {
            for (let i = 0; i < project.templates.length; i++) {
                let template = project.templates[i];
                if (template.value._id == idTemplate) {
                    return returnPath ? [template, 'templates[' + i + ']'] : template;
                }
            }
        }
    },

    /**
     * Search for the parent template ID containing the specified module, object or memory.
     * @param {Object} project - The project tree.
     * @param {string} id - The entity ID.
     * @returns {Object} The template object or null.
     */
    getTemplateByEntity: function(project, id) {
        if (project.templates && project.templates.length > 0) {
            for (let i = 0; i < project.templates.length; i++) {
                let template = project.templates[i];
                if (template.value._id == id || this.getBlockById(template, id) || this.getMemoryById(template, id))
                    return template;
            }
        }
        return null;
    },

    getSessionById: function(project, idSession, returnPath = false) {
        if(project.sessions && project.sessions.length > 0) {
            let foundIndex = project.sessions.findIndex(s => s.value._id === idSession);
            if (foundIndex != -1) {
                let session = project.sessions[foundIndex];
                return returnPath ? [session, 'sessions[' + foundIndex + ']'] : session
            }
        }
    },

    getEntityByIDInWholeProject: function( project,  idEntity, returnPath = false ){
        let tpl = this.getTemplateById(project, idEntity, returnPath);
        if (tpl) return tpl;

        let mem = this.getMemoryById( project, idEntity, returnPath );
        if( mem ) return mem;

        /*let asset = this.main.lib.getLoadedMediaByID( idEntity );
        if( asset ) return asset;*/

        let session = this.getSessionById( project, idEntity, returnPath );
        if( session ) return session;

        let bloc = this.getBlockById( project, idEntity, returnPath );
        if( bloc ) return bloc;

        return null;
    },

    getField: ( bloc, fieldname, index = false ) => {
        if( bloc.value.fields && bloc.value.fields.length > 0 ){
            
            for( let i=0; i<bloc.value.fields.length; i++ ){
                let field = bloc.value.fields[i];

                if( field.name == fieldname ){
                    return !index ? field : [field, i];
                }
            }
        }
        return null;
    },

    /**
     * Returns the current value of the dynamic field.
     *
     * @param {Obkect} bloc - The block of the current inspector from which to search.
     * @param {string} fieldname - The name of the dynamic field.
     * @param {boolean} index - Whether the result must include index or not.
     * @returns {Object or Array}
     */
    getDynamicFieldValue: (bloc, fieldname, index = false) => {
        if (bloc.value.dynamicFields && bloc.value.dynamicFields.length > 0) {
            for (let i = 0; i < bloc.value.dynamicFields.length; i++) {
                let field = bloc.value.dynamicFields[i];
                if (field.name == fieldname) {
                    return !index ? field : [field, i];
                }
            }
        }
        return null;
    },

    getCustomField: ( bloc, fieldname, index = false ) => {
        if( bloc.custom.fields && bloc.custom.fields.length > 0 ){
            
            for( let i=0; i<bloc.custom.fields.length; i++ ){
                let field = bloc.custom.fields[i];

                if( field.name == fieldname ){
                    return !index ? field : [field, i];
                }
            }
        }
        return null;
    },

    getFieldFromArray: ( bloc, fieldlabel, index = false ) => {
        if( bloc.value.fields && bloc.value.fields.length > 0 ){
            for( let i=0; i<bloc.value.fields.length; i++ ){
              let field = bloc.value.fields[i];
              if(field.name && fieldlabel.includes(field.name)) {
                return !index ? field : [field, i];
              }
            }
          }
        return null;
      },

	getValueOfField: function ( bloc , fieldFormat ){
		let value = null;

		// init value field with settings of the block type
		let field = this.getField( bloc, fieldFormat.name );

		if( field == null ){
			// Si des valeurs pour ce champ n'ont pas encore été setté en base, on prend les valeurs par default

			let pluggable = null;
			if( fieldFormat.type == "field" ){
				pluggable = {in:fieldFormat.connection.in.pluggable, out:fieldFormat.connection.out.pluggable};
			}
			let defaultValue = {name:fieldFormat.name, value:null, in:false, out:false, pluggable: pluggable };


			if( fieldFormat.default !== null && typeof fieldFormat.default === 'object')
				defaultValue.value = Object.assign( {}, fieldFormat.default ); // use a copy of the object, to not use the default object itself
			else
				defaultValue.value = fieldFormat.default;

			if( fieldFormat.connection && fieldFormat.connection.in.pluggable && fieldFormat.connection.in.default )
				defaultValue.in = true;

			if( fieldFormat.connection && fieldFormat.connection.out.pluggable && fieldFormat.connection.out.default )
				defaultValue.out = true;

			bloc.value.fields.push(defaultValue);
			let field = this.getField( bloc, fieldFormat.name );

			value = field;

		}
		else{
			// On récupérer la valeur contenue en base

			// On vérifie que le champ est toujours "pluggable" (modification possible du coté BDD)
			if( fieldFormat.type == "field" ){
				field.pluggable = {
					in:fieldFormat.connection.in.pluggable,
					out:fieldFormat.connection.out.pluggable
				};
				// Si ce n'est pas le cas, on remet a false
				if( !fieldFormat.connection.in.pluggable )
					field.in = false;

				if( !fieldFormat.connection.in.pluggable )
					field.in = false;
			}

			// On set la valeur en base
			value = field;
		}

		return value;
	},

    createArrayRowColField: function ( id, name, mode ){
        let label = mode ? mode+" " : "col ";
        return  {
            "type": "field",
            "name": id,
            "widget": "calculated",
            "default": null,
            "title": name,
            "label": label+name,
            "isArrayCell": true,
            "connection": {
                "in": {
                    "pluggable": true,
                    "default": false
                },
                "out": {
                    "pluggable": true,
                    "default": false
                },
                "activate": {
                    "pluggable": true,
                    "default": true
                }
            }
        }
    },

    getArrayRowCols: function( project, arrayID ){
        let memoryTarget = this.getMemoryById( project, arrayID );

        if( !memoryTarget )
            return [];

        let array = null;
        for (var i = 0; i < memoryTarget.value.fields.length; i++) {
            if( memoryTarget.value.fields[i].name == 'value' )
                array = memoryTarget.value.fields[i];
        }

        return array;
    },

    constructRepeatedForm: function( field ){
        field.type = "group";
        field.isRepeatedForm = true;
        field.isAccordion = true;
        field.children = [ ];
    },

    /**
     * Define the dynamic group and group field.
     * @param {Object} field — the dynamic field definition.
     * @returns Object
     */
    constructDynamicFieldsGroup: function (field) {
        field.type = "group";
        field.title = field.label;
        field.isDynamicFields = true;
        field.isAccordion = true;
        field.children = [
            {
                type: "group-field",
                name: field.group || `group-${field.label.toLowerCase().replace(' ', '-')}`,
                widget: 'dynamic-fields',
                children: [],
            }
        ];

        // remove unused keys to reflect the format of the "standard" field
        // DO NOT REMOVE field.widget AND field.group, hey are both important to list the associated dynamic fields
        // see storeDynamicFieldValues() method in nodal-authoring/src/actions/block/UpdateFields.js
        delete field.coreFormat;
        delete field.default;
        delete field.label;
        delete field.includedField;
    },

    blockIsUsedIn: function(node, blocId) {

        let blockisUsedIn = [];
        if (node.custom.children && node.custom.children.length > 0) {
            for (let i = 0; i < node.custom.children.length; i++) {
                let block = node.custom.children[i];
                if (block.value.type === "ui") {
                    block.value.fields.forEach(field => {
                        if(field.name === "moduleObjectId") {
                            if(field.value === blocId) {
                                let obj = {
                                    blocName: block.value.name,
                                    blocType: block.value.type,
                                    parentID: block.value.ref_process || block.value.ref_experience
                                }
                                blockisUsedIn.push(obj)
                            }
                        }
                    })
                }
                if (block.custom.children && block.custom.children.length > 0) blockisUsedIn = blockisUsedIn.concat(this.blockIsUsedIn(block, blocId));
            }
        }

        return blockisUsedIn;
    },

    memIsUsedIn: function(node, memId) {
        let memPossibleBlock = [
            "memory-access",
            "array-column-operator",
            "array-sort",
            "array-column",
            "array-row",
            "access-memory"
        ]

        let memisUsedIn = [];
        if (node.custom.children && node.custom.children.length > 0) {
            for (let i = 0; i < node.custom.children.length; i++) {
                let block = node.custom.children[i];

                if (memPossibleBlock.indexOf(block.value.type) !== -1) {
                    block.value.fields.forEach(field => {
                        if(field.name === "memoryId" || field.name === "arrayId") {
                            if(field.value === memId) {
                                let obj = {
                                    blocName: block.value.name,
                                    blocType: block.value.type,
                                    parentID: block.value.ref_process || block.value.ref_experience
                                }
                                memisUsedIn.push(obj)
                            }
                        }
                    })
                }
                if (block.custom.children && block.custom.children.length > 0) memisUsedIn = memisUsedIn.concat(this.memIsUsedIn(block, memId));
            }
        }
        return memisUsedIn;
    },

    /**
     * Remove all specified components in the given node.
     * @param {Object} node - The node to be cleaned.
     * @param {Array} IDs - The list of id referring to all component to delete.
     */
    cleanNode(node, IDs) {
        if (IDs.length == 0) return;

        // go through block children
        if (node.custom.children && node.custom.children.length > 0) {
            let cptBlock = 0;
            for (let subnode of node.custom.children) {

                if (IDs.indexOf(subnode.value._id) != -1) {
                    node.custom.children.splice(cptBlock, 1);
                }

                cptBlock++;
                this.cleanNode(subnode, IDs);
            }
        }
        // go through UIobjects children
        if (node.custom.objects && node.custom.objects.length > 0) {
            let cptObject = 0;
            for (let subnode of node.custom.objects) {

                if (IDs.indexOf(subnode.value._id) != -1) {
                    node.custom.objects.splice(cptObject, 1);
                }

                cptObject++;
                this.cleanNode(subnode, IDs);
            }
        }

        // go through memories
        if (node.memory && node.memory.length > 0) {
            node.memory = node.memory.filter( mem => IDs.indexOf(mem.value._id) === -1)
        }

        // Go through sessions
        if (node.sessions && node.sessions.length > 0) {
            node.sessions = node.sessions.filter( session => IDs.indexOf(session.value._id) === -1)
        }
    }
}

export default block;
